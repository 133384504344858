<template>
  <div class="page" :style="{'padding-top':(IfTitle?'-30px':'0px')}">
    <div class="account" v-if="IfTitle">发布内容</div>
    <!-- 筛选 -->
    <div class="selectBox">
      <!-- 平台类型 -->
      <PlatformCom ref="platform_type" :desc="'platform_type'"></PlatformCom>
      <!-- 主体类型 -->
      <PlatformCom ref="body_type" :desc="'body_type'"></PlatformCom>
      <!-- 条件栏 -->
      <el-row :gutter="20" style="position: relative">
        <el-col :span="20" class="selectBox-group-left">
          <!-- 条件 -->
          <div class="selectBox-group-item">
            <el-select class="w100" v-model="myInfo.fansSection" placeholder="请选择">
              <el-option v-for="item in myInfo.options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-input class="w190 m-r-8 m-l-8" v-model="myInfo.condition" clearable placeholder="请输入"></el-input>
          </div>
          <!-- 发布时间 -->
          <TimeEr :title="'发布时间'" ref="publishTime" class="selectBox-group-item" :timeType="'datetimerange'"></TimeEr>
          <!-- 所在地区 -->
          <AreaMany ref="areaMany" class="selectBox-group-item"></AreaMany>
        </el-col>
        <el-col :span="4">
          <div class="selectBox-group-btn">
            <div class="queryBtn" @click="queryFn">查询</div>
            <div class="resetBtn" @click="resetFn">重置</div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 列表 -->
    <div class="data-list">
      <!-- 加载中 -->
      <el-skeleton :rows="26" animated :loading="ifOpen" />
      <div class="data-box" v-for="(item, index) in queryList" :key="index">
        <div class="data-box-right">
          <div class="name-box">
            <div class="title" @click="detail(item)">
              <div class="status">{{ item.platformName }}</div>
              <div class="name" v-html="item.title ? item.title : ''"></div>
            </div>
          </div>
          <div class="content">
            <div class="content-text" v-html="item.content ? item.content : ''"></div>
          </div>
          <div class="data-info">
            <div class="data-info-left">
              <div class="subject-type texthidden tiao-account" @click="$main.goTaiZhang(item)" v-if="item.platformType == 1">
                网站名称：{{ item.source ||item.author || item.accountName|| "无" }}
              </div>
              <div class="subject-type tiao-account" @click="$main.goTaiZhang(item)" v-else>
                作者：<span v-html="item.author || '无'"></span>
              </div>
              <div class="subject-type">
                所在地区：{{ item.areaName || "无" }}
              </div>
              <div class="subject-type">
                发布时间：{{ item.publishTime || "无" }}
              </div>
            </div>
            <div class="data-info-right">{{ item.publishTime || "无" }}</div>
          </div>
          <div class="operation">
            <div class="operation-right">
              <!-- <div class="programme" @click="operation({ type: 1, item })" v-if="!item.collectFlag">
                <img src="../../assets/img/wang/collection.png" alt="" />
                <div>收藏</div>
              </div> -->
              <!-- <div class="programme" @click="operation({ type: 6, item })">
                <img src="../../assets/img/zhang/yishoucang.png" alt="" />
                <div style="color: #ff9000">已收藏</div>
              </div> -->

              <div class="detail" @click="$main.copyFun(item.copyText, '复制原文')">
                <img src="../../assets/img/wang/content-info.png" alt="" />
                <div>复制原文</div>
              </div>
              <div class="detail" @click="$main.copyFun(item.sourceUrl, '复制链接')">
                <img src="../../assets/img/wang/copy-link.png" alt="" />
                <div>复制链接</div>
              </div>
              <!-- <div
                class="detail"
                @click="$main.copyFun(item.content, '复制内容')" 
              >
                <img src="../../assets/img/wang/copy-content.png" alt="" />
                <div>复制内容</div>
              </div> -->
              <!-- <div class="remove" style="margin: 0" @click="deleteFnMy(item)">
                <img src="../../assets/img/wang/del.png" alt="" />
                <div>删除</div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- <img class="disposed" src="../../assets/img/wang/disposed.png" alt=""> -->
      </div>
      <!-- 位图 -->
      <Bitmap :desc="'列表'" v-if="queryList.length == 0&&!ifOpen" />
      <Pagination :pageNumber="page.page" :pageSize="page.limit" :total="total" @CurrentChange="CurrentChange" @SizeChange="SizeChange">
      </Pagination>
      <deleteDialog ref="openDialog" @deleteFn="operation"></deleteDialog>
    </div>
  </div>
</template>

<script>
import TimeEr from "../../components/dressing/TimeEr.vue";
import PlatformCom from "../../components/dressing/PlatformCom.vue";
import AreaMany from "../../components/areaMany.vue"; // 地区多选
import Bitmap from "../../components/bitmap.vue"; // 位图
import deleteDialog from "../../components/deleteDialog.vue";
import Pagination from "@/components/pagination.vue";
// import { Dictionaries} from "../../api/platformaccount/index";
import { getlist, update } from "../../api/publishContent/index";
export default {
  props: {
    IfTitle: {
      type: Number,
      default: 1
    }
  },
  components: {
    Pagination,
    deleteDialog,
    Bitmap,
    AreaMany,
    PlatformCom,
    TimeEr
  },
  data() {
    return {
      // 筛选栏目的信息
      myInfo: {
        ownRegion: [],
        ownRegionList: [
          //所属区域列表
        ],
        ownRegionProps: {
          //所属区域
          lazy: true,
          multiple: false,
          checkStrictly: true,
          lazyLoad: this.ownRegionLoadNode,
        },
        condition: "", //搜索条件
        fansSection: 1, //搜索描述
        options: [
          { label: "文章标题", value: 1 },
          { label: "文章内容", value: 2 },
          // {label:'信息来源',value:3},{label:'账号名称',value:4}
        ],
        infoType: null, //信息类型
        infoTypeOptions: [], //信息类型
        management: "", //所在地区
        managementOptions: [
          { label: "西安", value: 1 },
          { label: "北京", value: 2 },
        ], //所在地区
      },

      queryList: [], // 列表
      page: {
        page: 1,
        limit: 10,
      },
      total: 100,
      ifOpen: true,
    };
  },
  async created() {
    console.log(this.IfTitle)
    // 如果是别的页面点进来的 就会有值
    let params = this.$route.query.info
      ? JSON.parse(decodeURIComponent(this.$route.query.info))
      : null;
    if (params) {
      this.$nextTick(async () => {
        // 有筛选栏
        if (params.pageType == 1) {
          this.myInfo.accountId = params.accountId;
          this.$refs.platform_type.setData(params.platformType); //  回显平台类型多选框
          await this.$main.mySetTimeOut(200);
          this.queryFn();
        }
      })

    } else {
      await this.$main.mySetTimeOut(200);
      this.getQueryPage(); // 获取列表
    }
  },
  mounted() { },
  methods: {
    // goTaiZhang(item) {
    //   console.log(item);
    //   const routeObj = {
    //     path: "/accountDetail", 
    //     query: item
    //   }
    //   const { path, query } = routeObj;
    //   this.$main.routeResolve(path, query);
    // },
    // 通过key获取平台类型字典的值
    getKey(key) {
      let label = "";
      this.myInfo.PlatformObj.checkedData.forEach((v) => {
        if (v.value == key) {
          label = v.label;
        }
      });
      return label;
    },
    deleteFnMy(item) {
      item.message = "这条数据";
      this.$refs.openDialog.openDialog(item);
    },
    // 更新  已-未读 删除 收藏
    async operation({ type, item }) {
      // 操作类别-type: 1 收藏 2 删除 3 已读 4 未读 5 撤销删除 6 取消收藏
      const { id } = item;
      const obj = {
        dataIds: id,
        operationType: type,
        ...this.$main.snapshot(item.publishTime)
      }
      const Res = await update(obj);
      const { code } = Res.data;
      if (code == 200) {
        let typeText = "";
        if (type == 1) {
          typeText = "已收藏";
          item.collectFlag = true;
        } else if (type == 6) {
          typeText = "取消收藏";
          item.collectFlag = false;
        } else if (type == 2) {
          typeText = "已成功删除";
          // await this.$main.mySetTimeOut(1000)
          setTimeout(() => {
            this.queryFn();
          }, 1000);
        }
        this.$message({
          message: `${typeText ? typeText : "操作成功"}`,
          type: "success",
        });
      } else {
        this.$message.error("操作失败!");
      }
    },
    // 分页· 页码 
    CurrentChange(val) {
      this.page.page = val;
      this.queryFn({ ifpageType: 1 });
    },
    //  分页 条数
    SizeChange(val) {
      this.page.limit = val;
      // this.page.page = 1;
      this.queryFn();
    },
    // 搜索
    queryFn({ ifpageType } = {}) {
      //  type 存在 代表会返回成对象
      //  pageType 存在 代表是分页按钮点击的  如果不存在 那么分页默认第一页
      if (!ifpageType) {
        this.page.page = 1
      }
      const { fansSection, condition, ownRegion } = this.myInfo;
      const { $refs } = this;
      // console.log(this.$refs.publishTime.Time[1])
      // let endTime = this.$refs.publishTime.Time[1].split(' ')
      // console.log(endTime);
      // endTime = endTime[0]+' 23:59:59'
      // console.log(endTime);
      const obj = {
        bodyTypes: $refs.body_type.formObj.checkedform
          .map((v) => v.value)
          .join(","),
        platformTypes: $refs.platform_type.formObj.checkedform
          .map((v) => v.value)
          .join(","),
        publishTimeStart: $refs.publishTime.Time[0] || "",
        
        publishTimeEnd: $refs.publishTime.Time[1] || "",
      };
      // 从问题平台跳进来要用
      if (this.myInfo.accountId) obj.accountId = this.myInfo.accountId;

      if (ownRegion.length > 0) obj.areaIds = ownRegion.join(",");
      if (fansSection == 1 && condition.length > 0) obj.title = condition;
      if (fansSection == 2 && condition.length > 0) obj.content = condition;

      if (this.$refs.areaMany.ownRegion.length > 0) {
        const { backOwnRegion } = this.$refs.areaMany;
        obj.areaIds = `${backOwnRegion(0).join(",")},${backOwnRegion(1).join(
          ","
        )},${backOwnRegion(2).join(",")}`;
      }
      this.getQueryPage(obj);
    },
    // 获取列表
    async getQueryPage(obj) {
      this.ifOpen = true;
      this.queryList = [];
      if (!this.IfTitle) this.page = { ...this.page, isCollect: 1 }
      const { page } = this;
      try {
        const Res = await getlist(obj ? { ...obj, ...page } : page);
        const {
          data: { list, totalCount },
        } = this.$main.getResData(Res);
        this.queryList = this.$main.listImgNo(list);
        this.ifOpen = false;
        this.total = Number(totalCount);
      } catch {
        this.ifOpen = false;
      }
    },
    //详情
    detail(item) {
      this.$main.routeResolve("/publishingcontentDetai", item);
    },
    // 重置
    resetFn() {
      this.$refs.publishTime.Time = []; // 发布时间
      this.myInfo.fansSection = 1;
      this.myInfo.condition = "";
      this.$refs.platform_type.resetCom(); // 平台类型
      this.$refs.body_type.resetCom(); // 主体类型
      this.myInfo.ownRegion = [];
      this.$refs.areaMany.ownRegion = [];
      this.queryFn();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  .account {
    width: 120px;
    height: 37px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    line-height: 37px;
    margin-bottom: 24px;
  }
  .platform-head {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 0px 24px;
    height: auto;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    .platform-type {
      display: flex;
      min-height: 52px;
      width: 100%;
      height: auto;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #999999;
      border-bottom: 1px solid #e4e6ec;
      .platform-type-left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 16px;
        width: 96px;
        height: 100%;
      }
      .platform-type-right {
        display: flex;
        width: calc(100% - 96px);
        height: 100%;
        .check-all {
          //全选
          margin: 16px 48px 0px 0px;
          ::v-deep .el-checkbox__label {
            color: #333333;
          }
          ::v-deep .el-checkbox__inner {
            border-color: #e4e6ec;
          }
          ::v-deep .el-checkbox__input.is-checked {
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
          ::v-deep .el-checkbox__input.is-indeterminate {
            //半选
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
        }
        .checkbox-group {
          width: calc(100% - 100px);
          height: 100%;
          padding-bottom: 16px;
          .check-more {
            //多选
            margin: 16px 48px 0px 0px;
            ::v-deep .el-checkbox__label {
              color: #333333;
            }
            ::v-deep .el-checkbox__inner {
              border-color: #e4e6ec;
            }
          }
          ::v-deep .el-checkbox.check-more.is-checked {
            .el-checkbox__inner {
              background-color: #f58030;
              border-color: #f58030;
            }
          }
        }
        ::v-deep .el-radio {
          margin-top: 18px;
          margin-right: 34px;
          .el-radio__label {
            color: #333333;
          }
          .el-radio__inner {
            border-color: #e4e6ec;
          }
          .el-radio__input.is-checked {
            .el-radio__inner {
              background-color: #f58030;
            }
          }
        }
      }
    }
    .fans-box {
      display: flex;
      align-items: center;
      height: 68px;
      width: 100%;
      .fans-num {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-right: 12px;
        margin-left: 24px;
        white-space: nowrap;
      }
      .input {
        //输入框
        width: 240px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 240px;
          border-radius: 4px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .select {
        //下拉选择
        width: 120px;
        height: 36px;
        margin: 0px 24px 0 0px;
        ::v-deep .el-input__inner {
          width: 120px;
          height: 36px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .select-two {
        width: 190px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 190px;
          height: 36px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      ::v-deep
        .el-date-editor.el-range-editor.el-input__inner.lelect-time.el-date-editor--daterange {
        height: 36px;
        width: 280px;
        border-radius: 4px;
        .el-range-input {
          font-size: 13px;
          width: 100px;
        }
        .el-range-input:nth-child(4) {
          margin-left: 10px;
        }
        .el-input__icon.el-range__icon.el-icon-date {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .el-input__icon.el-range__close-icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .el-range-separator {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
        }
      }
      .search {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        width: 60px;
        height: 32px;
        background: #f58030;
        border-radius: 4px;
        margin-right: 16px;
        cursor: pointer;
        margin-left: 120px;
      }
      .reset {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        cursor: pointer;
      }
    }
    .screen-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: 36px;
      .select-type {
        width: 80px;
        height: 36px;
        margin-right: 24px;
        ::v-deep .el-input__inner {
          width: 80px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .input-content {
        width: 240px;
        height: 36px;
        ::v-deep .el-input__inner {
          height: 36px;
          width: 240px;
          border-radius: 4px;
        }
        ::v-deep .el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .address {
        font-size: 14px;
        white-space: nowrap;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 0 12px 0 24px;
      }
      .select-address {
        width: 190px;
        height: 36px;
        ::v-deep .el-input__inner {
          width: 190px;
          height: 36px;
          border-radius: 4px;
        }
        ::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-arrow-down {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-input__icon.el-icon-circle-close {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ::v-deep .el-cascader {
          height: 36px !important;
          margin-top: 0px;
          .el-input.el-input--suffix {
            height: 36px;
            display: flex;
            align-items: center;
          }
        }
      }
      .query {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        background: #f58030;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-left: 70px;
        cursor: pointer;
      }
      .data-import {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 97px;
        height: 36px;
        background: #fff1e8;
        border-radius: 4px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ed731f;
        margin-left: 16px;
        cursor: pointer;
        img {
          height: 14px;
          width: 14px;
          margin-right: 2px;
        }
      }
      .reset {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e1e1e1;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }
  .account-number {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 24px;
    width: 100%;
    height: 68px;
    .add-account {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 128px;
      height: 36px;
      background: #f58030;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
      img {
        height: 12px;
        width: 12px;
        margin-right: 2px;
      }
    }
  }
  .data-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #ffffff;
    border-radius: 4px;
    padding: 24px 40px;
    margin-top: 16px;
    .data-box {
      display: flex;
      align-items: center;
      width: 100%;
      height: auto;
      padding: 24px 0px;
      border-bottom: 1px solid #e4e6ec;
      .disposed {
        height: 114px;
        width: 129px;
        position: absolute;
        right: 138px;
      }
      .data-box-left {
        width: 132px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .type {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          height: 28px;
          background: #ff4b1f;
          border-radius: 1px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .platform-type {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          width: auto;
          background: #f58030;
          max-width: 104px;
          padding: 0px 24px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          margin-top: 24px;
        }
      }
      .data-box-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .name-box {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          width: 100%;
          height: 25px;
          margin-bottom: 16px;

          .title {
            display: flex;
            width: 100%;
            height: 25px;
            .status {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              background: #f58030;
              padding: 0 8px;
              margin-right: 16px;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
            .name {
              height: 25px;
              max-width: calc(100% - 160px);
              width: auto;
              line-height: 25px;
              font-size: 18px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: bold;
              color: #333333;
              cursor: pointer;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
            }
            .bad-num {
              display: flex;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2e59ec;
              margin-left: 18px;
              cursor: pointer;
              img {
                height: 16px;
                width: 18px;
                margin-right: 8px;
              }
            }
          }
          .read {
            display: flex;
            justify-content: flex-end;
            width: 20%;
            .similar {
              display: flex;
              align-items: center;
              width: auto;
              height: 25px;
              margin-right: 8px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
            .unread {
              display: flex;
              align-items: center;
              width: auto;
              height: 25px;
              img {
                width: 17px;
                height: 12px;
                margin-right: 8px;
              }
              span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ea3342;
              }
            }
          }
        }
        .content {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 16px;
          .content-text {
            width: 100%;
            max-height: 40px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .data-info {
          display: flex;
          height: 20px;
          width: 100%;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 16px;
          .data-info-left {
            display: flex;
            align-items: center;
            width: 80%;
            height: 20px;
            .subject-type {
              max-width: 600px;
              margin-right: 46px;
            }
            
          }
          .data-info-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 20px;
            width: 20%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          .status {
            color: #02bc7c;
            margin-right: 46px;
          }
          .region {
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }

          .company {
            max-width: 300px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-right: 46px;
          }
          .fnas {
            margin-right: 46px;
          }
        }
        .operation {
          display: flex;
          height: 20px;
          width: 100%;
          .operation-left {
            display: flex;
            align-items: center;
            width: 20%;
            height: 100%;
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-right: 8px;
            }
          }
          .operation-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            .detail {
              display: flex;
              height: 100%;
              width: auto;
              align-items: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
              margin-right: 40px;
              img {
                height: 14px;
                width: 13px;
                margin-right: 7px;
              }
            }
          }
          .programme {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            margin-right: 40px;
            img {
              height: 14px;
              width: 15px;
              margin-right: 7px;
            }
          }
          .remove {
            display: flex;
            height: 100%;
            width: auto;
            align-items: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            cursor: pointer;
            margin-right: 24px;
            img {
              height: 15px;
              width: 15px;
              margin-right: 7px;
            }
          }
        }
      }
    }
  }
}
</style>