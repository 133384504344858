// 发布内容
import request from '../request.js';
// 获取列表
export function getlist(data) {
    return request({
        url:"module-polyse/publishContent/list",
        method:"post",
        data
    })
}
//修改发布内容(已-未读。删除。收藏)
export function update(data) {
    return request({
        url:"module-polyse/publishContent/update",
        method:'post',
        data
    })
}
// 详情
// module-polyse/publishContent/detail/{dataId}
export function detail(data) {
    return request({
        url:`module-polyse/publishContent/detail`,
        method:'post',
        data
    })

}