<template>
  <!-- 时间 -->
  <div>
    <span v-if="title"> {{ title }} </span>
    <el-date-picker v-model="Time" class="lelect-time m-l-8" :type="timeType" :value-format="format" range-separator="-" start-placeholder="开始日期"
                    end-placeholder="结束日期" :default-time="['','23:59:59']" :picker-options="pickerOptions" @focus="clearTime">
    </el-date-picker>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    timeType: {
      type: String,
      default: "daterange",
    },
    format: {
      type: String,
      default: "yyyy-MM-dd HH:mm:ss",
    },
  },
  data() {
    return {
      Time: [],
      pickerMinDate: "",//第一次选中的时间
      pickerOptions: {
        onPick: obj => {
          this.pickerMinDate = new Date(obj.minDate).getTime();
          console.log(this.pickerMinDate);//第一次选中的时间戳
        },
        disabledDate: time => {
          if (this.pickerMinDate) {
            //获取选中月份的1号
            let a = new Date(this.pickerMinDate).setDate(1)
            //获取选中月
            let yue = new Date(this.pickerMinDate).getMonth()
            let yue1 = ++yue
            // let oneDay = 1000 * 60 * 60 * 24
            let oneDay = 1000  //选择到23.59.59
            //最后一天
            let b = new Date(new Date(this.pickerMinDate).getFullYear(), yue1, 1) - oneDay
            return time.getTime() > b || time.getTime() < a;
          }
        }
      },
    };
  },
  methods:{
    clearTime(){
      this.pickerMinDate = ''
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep
  .el-date-editor.el-range-editor.el-input__inner.lelect-time.el-date-editor--daterange {
  height: 36px;
  width: 280px;
  border-radius: 4px;
  .el-range-input {
    font-size: 13px;
    width: 100px;
  }
  .el-range-input:nth-child(4) {
    margin-left: 10px;
  }
  .el-input__icon.el-range__icon.el-icon-date {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-input__icon.el-range__close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-range-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}
</style>